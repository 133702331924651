<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24"
    viewBox="0 0 18 24"
  >
    <g class="a" transform="translate(-4)">
      <path
        class="c"
        d="M13,0C8.03,0,4,3.761,4,8.4a7.885,7.885,0,0,0,.827,3.5,46.378,46.378,0,0,0,4.786,6.535A33.2,33.2,0,0,1,13,24a27.178,27.178,0,0,1,3.273-5.565,47.473,47.473,0,0,0,4.9-6.535A7.885,7.885,0,0,0,22,8.4C22,3.761,17.97,0,13,0Z"
      />
      <path
        class="d"
        d="M 13 2 C 9.140190124511719 2 6 4.871120452880859 6 8.400199890136719 C 6 9.300600051879883 6.208759307861328 10.18074035644531 6.620800018310547 11.0168399810791 C 6.982149124145508 11.75074005126953 8.062219619750977 13.41269969940186 11.15579032897949 17.16266059875488 C 11.79026794433594 17.93190002441406 12.40798854827881 18.85384941101074 12.94933032989502 19.74067687988281 C 13.46989154815674 18.86396789550781 14.07740116119385 17.94761657714844 14.7348804473877 17.15666007995605 C 16.31816864013672 15.25224018096924 18.71085929870605 12.37425994873047 19.37952041625977 11.01618957519531 C 19.79124069213867 10.18074035644531 20 9.300600051879883 20 8.400199890136719 C 20 4.871120452880859 16.85980987548828 2 13 2 M 13 0 C 17.97024917602539 0 22 3.761190414428711 22 8.400199890136719 C 22 9.651129722595215 21.69924926757812 10.83345985412598 21.17350006103516 11.90028953552246 C 20.41090965270996 13.44915008544922 18.11808013916016 16.2157096862793 16.27280044555664 18.43524932861328 C 14.45390033721924 20.62340927124023 13 24 13 24 C 13 24 11.33920955657959 20.52821922302246 9.612899780273438 18.43524932861328 C 7.614030838012695 16.01226043701172 5.543569564819336 13.35667991638184 4.826499938964844 11.90028953552246 C 4.300750732421875 10.83345985412598 4 9.651129722595215 4 8.400199890136719 C 4 3.761190414428711 8.029750823974609 0 13 0 Z"
      />
    </g>
    <circle class="b" cx="2" cy="2" r="2" transform="translate(7 6)" />
  </svg>
</template>

<script>
export default {
  name: "Location",
};
</script>

<style lang="scss" scoped>
.a {
  fill: none;
  stroke-miterlimit: 10;
}
.b {
  fill: #2d323c;
}
.c,
.d {
  stroke: none;
}
.d {
  fill: #2d323c;
}
</style>